import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sinceTime'
})
export class SinceTimePipe implements PipeTransform {

  transform(time: number) {
          let ber:number = Math.floor((Date.now()-time)/60000);
          let vor = 'vor '+ber+' Minuten';
          if(ber <= 3) {vor = 'gerade eben'}
          if(ber >= 60){ vor = 'mehr als einer Stunde'}
          if(ber >= 120){ vor = 'mehr als '+Math.floor(ber/60)+' Stunden'}
          if(ber >= 120) {
            let stunden = Math.floor(ber/60);
            let minuten = Math.floor((ber-(stunden*60)));
            vor = 'vor mehr als '+stunden+' Stunden und '+minuten+' Minuten';
          };
          if(ber >= 1440) {

            let tage = Math.floor(ber/1440);
            let stunden = Math.floor((ber-(tage*1440))/60);
            vor = 'vor mehr als einem Tag und '+stunden+' Stunden';
            if(ber >= 2880) {vor = 'vor mehr als '+tage+' Tagen und '+stunden+' Stunden';}
          };
          return vor;
  }

}
