import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'loader', //loader // veranstaltung
    pathMatch: 'full'
  },
  {
    path: 'loader',
    loadChildren: () => import('./pages/loader/loader.module').then( m => m.LoaderPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./pages/verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'order-detail/:id',
    loadChildren: () => import('./pages/order-detail/order-detail.module').then( m => m.OrderDetailPageModule)
  },
  {
    path: 'new-order',
    loadChildren: () => import('./pages/new-order/new-order.module').then( m => m.NewOrderPageModule)
  },
  {
    path: 'new-produkt',
    loadChildren: () => import('./pages/new-produkt/new-produkt.module').then( m => m.NewProduktPageModule)
  },
  {
    path: 'new-table-order',
    loadChildren: () => import('./pages/new-table-order/new-table-order.module').then( m => m.NewTableOrderPageModule)
  },
  {
    path: 'new-table-order/:id',
    loadChildren: () => import('./pages/new-table-order/new-table-order.module').then( m => m.NewTableOrderPageModule)
  },
  {
    path: 'freigabe',
    loadChildren: () => import('./pages/order-detail-new/order-detail-new.module').then( m => m.OrderDetailNewPageModule)
  },
  {
    path: 'oap',
    loadChildren: () => import('./pages/orderAndPay/oap.module').then( m => m.OapPageModule)
  },
  {
    path: 'zuweisen',
    loadChildren: () => import('./pages/zuweisen/zuweisen.module').then( m => m.ZuweisenPageModule)
  },
  {
    path: 'qrcode',
    loadChildren: () => import('./pages/qrcode/qrcode.module').then( m => m.QrcodePageModule)
  },
  {
    path: 'druckserver',
    loadChildren: () => import('./pages/druckserver/druckserver.module').then( m => m.DruckserverPageModule)
  },
  {
    path: 'abrechnung',
    loadChildren: () => import('./pages/abrechnung/abrechnung.module').then( m => m.AbrechnungPageModule)
  },
  {
    path: 'test',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'test/:id',
    loadChildren: () => import('./pages/test/test.module').then( m => m.TestPageModule)
  },
  {
    path: 'kellner-login',
    loadChildren: () => import('./pages/kellner-login/kellner-login.module').then( m => m.KellnerLoginPageModule)
  },
  {
    path: 'neu',
    loadChildren: () => import('./pages/neue-versanstaltung/neue-versanstaltung.module').then( m => m.NeueVersanstaltungPageModule)
  },
  {
    path: 'veranstaltung',
    loadChildren: () => import('./pages/veranstaltung/veranstaltung.module').then( m => m.VeranstaltungPageModule)
  },
  {
    path: 'produkte',
    loadChildren: () => import('./pages/produkte/produkte.module').then( m => m.ProduktePageModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./pages/user/user.module').then( m => m.UserPageModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'bontopayment',
    loadChildren: () => import('./pages/bontopayment/bontopayment.module').then( m => m.BontopaymentPageModule)
  },
  {
    path: 'update',
    loadChildren: () => import('./pages/update/update.module').then( m => m.UpdatePageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
