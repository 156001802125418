import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { environment } from '../environments/environment';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { LockProduktPipe } from './pipes/lock-produkt.pipe';
import { DecimalPipe } from '@angular/common';
import { SortOAPPipe } from './pipes/sort-oap.pipe';
import { ProduktUserPipe } from './pipes/produkt-user.pipe';
import { SinceTimePipe } from './pipes/since-time.pipe';
// import { MotosoPipe } from './pipes/motoso.pipe';
// import { WochentagPipe } from './pipes/wochentag.pipe';
// import { OapSortPipe } from './pipes/oap-sort.pipe';
// import { OapSortProductsPipe } from './pipes/oap-sort-products.pipe';
// import { NachtimestampPipe } from './pipes/nachtimestamp.pipe';


@NgModule({
  declarations: [AppComponent, LockProduktPipe, SortOAPPipe,ProduktUserPipe, SinceTimePipe],
  // entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireDatabaseModule,
    // QRCodeModule
  ],
  providers: [
    //  NetworkInterface,
    DecimalPipe,
    Insomnia,
    ScreenOrientation,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    // AndroidFullScreen,
     { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
