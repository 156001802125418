import { Pipe, PipeTransform } from '@angular/core';
import { doNotListenENV } from 'src/environments/environment';

@Pipe({
  name: 'produktUser'
})
export class ProduktUserPipe implements PipeTransform {

  transform(value: any): any {
    var doNotListen = doNotListenENV;
    if(true){
      var array = {};
      Object.keys(value).forEach(key =>{
        if(doNotListen.includes(key) === false){
          array[key]=(value[key]);
        }
      })
      console.log(array);
      var sorted = Object.keys(array)
      .sort((a, b) => {
        console.log(array[b].type);
        const gA= array[a].type;
        const gB= array[b].type;
        if(gA > gB){
          return -1;
        }
        if(gA < gB){
          return 1;
        }
          return 0;
      })
      .map(category => {
        console.log(category);
        if(doNotListen.includes(category) === false)
        {
          array[category]['key'] = '';
          array[category]['key'] = category;
        }
        return array[category];
      });
      console.log(sorted);
      const result = sorted.filter(element =>{
        return element !== undefined;
      });
      console.log(result);
      return result;
  }
}
}
